// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #d0282c !default;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$custom-danger: #790100 !default;


$grid-breakpoints: (
    xs: 0,
    sm: 768px,
    md: 992px,
    lg: 1300px,
    xl: 1400px,
    xxl: 1900px
);

$container-max-widths: (
    sm: 600px,
    md: 800px,
    lg: 1000px,
    xl: 1220px,
    xxl: 1610px
);

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
    "custom-danger": $custom-danger,
);


@import "~bootstrap/scss/bootstrap";
